<template>
  <div class="partnerRecruitment">
    <div class="banner-wrapper">
      <img src="../assets/partner_recruitment/banner.png" alt="">
    </div>
    <div class="description-wrapper">
      <div class="title-wrapper">
        <UnderlineTitle>招募概述</UnderlineTitle>
      </div>
      <div class="content">
        <ul class="infoList">
          <li v-for="item in infoList" :key="item.id">
            <h3 class="title">{{ item.title }}</h3>
            <span class="subTitle">{{ item.subTitle }}</span>
            <ul class="contact">
              <li>
                <span>联系电话</span>
                <span>4006864666</span>
              </li>
              <li>
                <span>联系邮箱</span>
                <span>dercx@sina.com</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="cooperation-wrapper">
      <div class="title-wrapper">
        <UnderlineTitle>合作方式</UnderlineTitle>
      </div>
      <div class="content">
        <ul class="cooperationList">
          <li v-for="item in cooperationList" :key="item.id">
            <img :src="item.image" alt="">
            <h3 class="title">{{ item.title }}</h3>
            <span class="subTitle">{{ item.subTitle }}</span>
            <ul class="featureList">
              <li v-for="feature in item.featureList" :key="feature">
                {{ feature }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="process-wrapper">
      <div class="title-wrapper">
        <UnderlineTitle>加入流程</UnderlineTitle>
      </div>
      <div class="content">
        <ul class="steps">
          <li v-for="item in steps" :key="item.id">
            <img :src="item.image" alt="">
            <span class="underline"></span>
            <span>{{ item.text }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'partnerRecruitment',
    data(){
      return {
        infoList: [
          {
            id: 1,
            title: '全国CP招募中',
            subTitle: '合作共赢 携手向上',
          },
          {
            id: 2,
            title: '全国DP招募中',
            subTitle: '成就伙伴 共享机遇',
          },
        ],
        cooperationList: [
          {
            id: 1,
            image: require('../assets/partner_recruitment/cooperation_1.png'),
            title: 'CP合作',
            subTitle: '车辆运营',
            featureList: ['租金定价','车辆保险','车辆维保','资产安全'],
          },
          {
            id: 2,
            image: require('../assets/partner_recruitment/cooperation_2.png'),
            title: 'DP合作',
            subTitle: '司机运营',
            featureList: ['司机管理','司机支持','司机获取','司机培训'],
          },
        ],
        steps: [
          {
            id: 1,
            image: require('../assets/partner_recruitment/step_1.png'),
            text: '意向申请',
          },
          {
            id: 2,
            image: require('../assets/partner_recruitment/step_2.png'),
            text: '准入申请',
          },
          {
            id: 3,
            image: require('../assets/partner_recruitment/step_3.png'),
            text: '合作签约',
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.partnerRecruitment {
  > .banner-wrapper {
    width: 100%;
    > img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  .title-wrapper {
      padding: 80px 0 45px 0;
    }
  > .description-wrapper {
    background-color: #f7f7f7;
    padding-bottom: 100px;
    > .content {
      padding: 20px;
      background-image: url('../assets/partner_recruitment/map.png');
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      width: 67.71vw;
      min-width: 950px;
      margin: 0 auto;
      padding: 130px 11.98vw 160px 6.04vw;
      > .infoList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > li {
          > .title {
            background-color: #f1981a;
            color: #fff;
            font-size: 40px;
            padding: 12px 30px;
          }
          > .subTitle {
            font-size: 32px;
            font-weight: 300;
            padding-left: 6px;
          }
          > .contact {
            margin-top: 40px;
            > li {
              > span {
                display: block;
                font-size: 24px;
                line-height: 1.8;
              }
              > span:first-child::before {
                display: inline-block;
                content: '';
                width: 7px;
                height: 25px;
                background-color: #f1981a;
                margin-right: 10px;
                transform: translateY(4px);
              }
              > span:last-child {
                padding-left: 20px;
                font-weight: 200;
              }
            }
          }
        }
      }
    }
  }
  .cooperation-wrapper {
    .content {
      .cooperationList {
        display: flex;
        justify-content: space-between;
        width: 56.46vw;
        margin: 0 auto;
        > li {
          width: 23.75vw;
          img {
            width: 23.75vw;
          }
          .title {
            font-size: 42px;
            padding-left: 3.13vw;
            margin-top: 26px;
          }
          .title::after {
            display: block;
            content: '';
            width: 200px;
            height: 4px;
            background-color: #d39e52;
            margin: 10px 0;
          }
          .subTitle {
            font-size: 34px;
            padding-left: 3.13vw;
            display: block;
          }
          .featureList {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            display: flex;
            margin-left: 3.13vw;
            margin-top: 54px;
            > li {
              // border: 1px solid red;
              display: inline-block;
              padding-right: 1.5vw;
              // padding-right: 30px;
              // font-size: 24px;
              font-size: 1.25vw;
              font-weight: 200;
              margin-bottom: 20px;
            }
            > li::before {
              display: inline-block;
              content: '';
              width: 12px;
              height: 12px;
              background-color: #f5951a;
            }
          }
        }
      }
    }
  }
  .process-wrapper {
    background-image: url('../assets/partner_recruitment/bottom_banner.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 75px;
    > .content {
      text-align: center;
      padding-bottom: 100px;
      > .steps {
        width: 52.86vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        > li {
          text-align: center;
          > .underline {
            display: block;
            width: 42px;
            height: 10px;
            background-color: #ed9819;
            margin: 0 auto;
            margin-top: 36px;
            margin-bottom: 26px;
          }
          > span {
            display: block;
            font-size: 40px;
          }
        }
      }
    }
  }
}
</style>